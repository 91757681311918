/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

import "./node_modules/bootstrap/dist/css/bootstrap-reboot.css";
import "./node_modules/bootstrap/dist/css/bootstrap-grid.css";
import "./node_modules/bootstrap/dist/css/bootstrap.css";

import "./node_modules/jquery/dist/jquery";
import "./node_modules/bootstrap/dist/js/bootstrap.bundle";

import "./src/styles/typography.css";
import "./src/styles/global.css";


